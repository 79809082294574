import React from "react";
import Seo from "../components/layout/Seo";
import {graphql, useStaticQuery} from "gatsby";
import {SmallHero} from "../components/global_component/small_hero/SmallHero";
import AboutPageCard from "../components/aboutPage/AbouPageCard/AboutPageCard";


export default function About(){
    const data = useStaticQuery(graphql`
    {
      allWpPage(filter: {id: {eq: "cG9zdDoxNDcz"}}) {
        nodes {
          aboutUs {
            aboutHero {
              aboutHeroTitle
              aboutHeroBanner {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
    const title = data?.allWpPage?.nodes[0]?.aboutUs?.aboutHero?.aboutHeroTitle;
    const banner = data?.allWpPage?.nodes[0]?.aboutUs?.aboutHero?.aboutHeroBanner?.localFile?.childImageSharp?.gatsbyImageData;

    return(
        <>
            <Seo title={"About Us"} />
            <SmallHero banner={banner} title={title} />
            <AboutPageCard/>
        </>
    )
}