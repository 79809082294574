import styled from "styled-components";
import {sm, md, xs, xxs} from "../../../styles/utils/media_queries";


const Wrapper = styled.div`
  padding: 25px 0;
  ${xs(`
    padding-top: 35px;
    padding-bottom: 13%;
    `)}
`;
const Card = styled.div`
  margin-bottom: 20px;
  border: 1px solid #000;

  &:last-child {
    margin-bottom: 0;
  }

  ${xs(`
    margin-bottom: 25px;
    display: flex;
      &:nth-child(even){
        .card-img{
          border-left: 1px solid #000;
          order: 1;      
        }
      }
      &:nth-child(odd){
        .card-img{
          border-right: 1px solid #000;
        }
      }
      img{
        position: absolute !important;
      }
    `)}
  ${sm(`
        margin-bottom: 35px;
    `)}
  h2 {
    font-family: 'Integral CF Bold';
    font-size: 24px;
    font-style: italic;
    margin-bottom: 15px;
    ${xxs(`
        font-size: 32px;
    `)}
    ${xs(`
        font-size: 45px;
        margin-bottom: 25px;
    `)}
    ${md(`
        font-size: 64px;
        margin-bottom: 35px;
    `)}
  }

  .card-img {
    border: 0;
    max-height: 300px;
    border-bottom: 1px solid #000;

    ${xs(`
      border-bottom: 0;
      width: 50%;
      max-height: none;
    `)}
    ${md(`
      width: 51%;
    `)}
    &.contain {
      position: relative;
      background-color: #612c8f;

      //&::after {
      //  position: absolute;
      //  content: "";
      //  top: 0;
      //  left: 0;
      //  width: 100%;
      //  height: 100%;
      //  background: linear-gradient(90deg, rgb(255, 255, 255) 0%, rgb(255, 255, 255) 10%, rgba(98, 44, 142, 0) 50%, rgb(255, 255, 255) 90%, rgba(255, 255, 255, 1) 100%);
      //}

      img {
        object-fit: contain !important;
      }
    }
  }
`;
const Content = styled.div`
  padding: 3% 4%;
  ${xs(`
        width: 50%;
    `)}
  ${md(`
        padding: 4.5% 7% 4%;
        width: 49%;
    `)}
`;
const Text = styled.div`
  font-size: 14px;
  ${xs(`
        font-size: 16px;
    `)}
  ${md(`
        font-size: 18px;
    `)}
  p{
    margin-top: 0;
    margin-bottom: 16px;
  }
`;




export{
    Card,
    Text,
    Content,
    Wrapper,
};
