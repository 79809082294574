import React from "react";
import { Card,Content,Text,Wrapper } from "./AboutPageCard.styled";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import {graphql, useStaticQuery} from "gatsby";
import lastImg from "../../../images/adcann_2021.jpg";


export default function AboutPageCard(){
    const data = useStaticQuery(graphql`
    {
      allWpPage(filter: {id: {eq: "cG9zdDoxNDcz"}}) {
        nodes {
          aboutUs {
            aboutCard {
              aboutCardTitle
              aboutCardContent {
                aboutCardContentP
              }
              aboutCardImg {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    }   
  `)
    const cardsAbout = data?.allWpPage?.nodes[0]?.aboutUs?.aboutCard;
    return(
        <div className="container">
            <Wrapper>
                {cardsAbout?.map?.((item, key) => (
                    <Card key={key}>
                        <GatsbyImage
                            className="card-img"
                            layout={"constrained"}
                            image={getImage(item?.aboutCardImg?.localFile?.childImageSharp?.gatsbyImageData)}
                            alt={item?.aboutCardTitle}
                        />
                        <Content>
                            <h2>{item?.aboutCardTitle}</h2>
                            <Text>
                                {item?.aboutCardContent?.map?.((text, key) => (
                                    <p key={key}>{text.aboutCardContentP}</p>
                                ))}
                            </Text>
                        </Content>
                    </Card>
                ))}
                
                <Card>
                    <div className={"gatsby-image-wrapper card-img contain"}>
                        <img src={lastImg} alt="adcann_2021"/>
                    </div>

                    <Content>
                        <h2>ADCANN 2021</h2>
                        <Text>
                            <p>Best Independent Retail Brand</p>
                            <p>THC Canada is the recipient of ADCANN's Best Independent Retail Brand 2021 Award.</p>
                        </Text>
                    </Content>
                </Card>
            </Wrapper>
        </div>
    )
};